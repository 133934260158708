<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path d="M17.4978 22.1055H13.2876V23.9767H17.4978V22.1055Z" fill="inherit"/>
    <path
        d="M22.0821 20.1853V21.1209C22.0821 21.6637 21.6405 22.1053 21.0977 22.1053H20.1621V23.9765H21.0977C22.6723 23.9765 23.9533 22.6955 23.9533 21.1209V20.1853H22.0821Z"
        fill="inherit"/>
    <path d="M10.6699 0.0234375H6.45972V1.89465H10.6699V0.0234375Z" fill="inherit"/>
    <path d="M17.4978 0.0234375H13.2876V1.89465H17.4978V0.0234375Z" fill="inherit"/>
    <path
        d="M2.85755 0.0229492C1.28291 0.0229492 0.00195312 1.30396 0.00195312 2.87854V3.81413H1.87317V2.87854C1.87317 2.33573 2.31478 1.89416 2.85755 1.89416H3.79313V0.0229492H2.85755Z"
        fill="inherit"/>
    <path
        d="M21.0977 0.0234375H20.1621V1.89465H21.0977C21.6405 1.89465 22.0821 2.33626 22.0821 2.87903V3.81462H23.9533V2.87903C23.9533 1.30444 22.6723 0.0234375 21.0977 0.0234375Z"
        fill="inherit"/>
    <path d="M1.87121 6.71484H0V10.925H1.87121V6.71484Z" fill="inherit"/>
    <path d="M24.0001 13.5426H22.1289V17.7528H24.0001V13.5426Z" fill="inherit"/>
    <path d="M24.0001 6.71484H22.1289V10.925H24.0001V6.71484Z" fill="inherit"/>
    <path
        d="M12.3986 4.84375V6.71492H16.054L9.88415 13.1613C9.45815 12.9003 8.95752 12.7495 8.42235 12.7495H2.80875C1.26107 12.7495 0.00195312 14.0087 0.00195312 15.5563V21.1699C0.00195312 22.7176 1.26107 23.9767 2.80875 23.9767H8.42235C9.97003 23.9767 11.2291 22.7176 11.2291 21.1699V15.5563C11.2291 15.231 11.1728 14.9187 11.0706 14.6278L17.3106 8.10829V11.6268H19.1818V4.84375H12.3986ZM9.35793 21.1699C9.35793 21.6858 8.93821 22.1055 8.42235 22.1055H2.80875C2.29284 22.1055 1.87317 21.6858 1.87317 21.1699V15.5563C1.87317 15.0404 2.29289 14.6207 2.80875 14.6207H8.42235C8.93826 14.6207 9.35793 15.0404 9.35793 15.5563V21.1699Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-upload-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 24 24'
    }
  }
}
</script>
